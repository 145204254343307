const FEATURE_FLAGS = {
  INVITE_ONLY_MODE: true,
}

// Until we get proper feature flags via optimizely...
const getFeatureFlags = async () => {
  return FEATURE_FLAGS
}

export { getFeatureFlags }
