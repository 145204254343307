import React, { useEffect } from 'react'
import firebase from 'firebase/app'
import ReactGA from 'react-ga'
import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import 'firebase/auth'
import 'firebase/firestore'
import debug from 'debug'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Switch, Route } from 'react-router-dom'
import { Landing } from './screens/Landing'
import { EmailLoginStart } from './screens/EmailLoginStart'
import { Teams } from './screens/LoggedInHome'
import { EmailLoginEnd } from './screens/EmailLoginEnd'
import { userContext } from './util/user-context'
import { useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
import { Onboard } from './screens/Onboard'
import { PageNotFound } from './screens/PageNotFound'
import { routes } from './util/routes'
import { StripeScreen } from './screens/StripeScreen'
import { analytics } from './util/analytics'

if (process.env.NODE_ENV !== 'production') {
  debug.enable('app:*')
}

// todo(johnrjj) - use env vars
const firebaseConfig = {
  apiKey: 'AIzaSyB6Z3H7y6LfCcw_UFIzbuWfy6Ehv64DKaY',
  authDomain: 'corpoclash.firebaseapp.com',
  databaseURL: 'https://corpoclash.firebaseio.com',
  projectId: 'corpoclash',
  storageBucket: 'corpoclash.appspot.com',
  messagingSenderId: '27785364867',
  appId: '1:27785364867:web:c5528da1366a2ea20162b6',
  measurementId: 'G-QVJDWB75LY',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const GOOGLE_ANALYTICS_KEY = 'G-GY66DQJYN8'
const OPTIMIZELY_KEY = process.env.NODE_ENV === 'production' ? '4orMWi9JuMxrZfx1BeQBKV' : 'UsQyAcBo13hmYKWvZDscad'

const optimizely = createInstance({
  sdkKey: OPTIMIZELY_KEY,
})

// const db = firebase.firestore()

// db.enablePersistence({
//   synchronizeTabs:true,
// }).catch(function(err) {
//   console.error(err)
// })

const App: React.FC = () => {
  const location = useLocation()

  // Set up analytics tracking...
  React.useEffect(() => {
    const enabledAnalytics = process.env.NODE_ENV === 'production'
    if (enabledAnalytics) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY)
    }
  }, [])

  const [pageTransitionsEnabled, setPageTransitionsEnabled] = React.useState<boolean>(true)
  // Disable page transitions at the app routing layer
  if (location.pathname.includes('/app') && pageTransitionsEnabled === true) {
    setPageTransitionsEnabled(false)
  }

  const transitions = useTransition(location as any, (location: Location) => location.pathname, {
    // config: config.slow,
    from: pageTransitionsEnabled
      ? {
          position: 'absolute',
          opacity: 0,
          transform: 'translateY(-5px)',
        }
      : {},
    enter: pageTransitionsEnabled
      ? {
          delay: 700, // Per-item delay
          opacity: 1,
          position: 'absolute',
          transform: 'translateY(0px)',
        }
      : {},
    leave: pageTransitionsEnabled
      ? {
          opacity: 0,
          position: 'absolute',
          transform: 'translateY(5px)',
        }
      : {},
    initial: null,
  })

  const [firebaseUser, loading, _error] = useAuthState(firebase.auth())

  // Identify user
  useEffect(() => {
    if (!firebaseUser || !firebaseUser.uid) {
      return
    }
    analytics.identify(firebaseUser.uid, {
      email: firebaseUser.email || undefined,
      emailVerified: firebaseUser.emailVerified || undefined,
    })
  }, [firebaseUser])

  return (
    <React.Fragment>
      <userContext.Provider
        value={{
          firebaseUser: firebaseUser,
          loading,
        }}
      >
        <OptimizelyProvider
          optimizely={optimizely}
          user={
            firebaseUser
              ? {
                  id: firebaseUser.uid,
                }
              : undefined
          }
        >
          {transitions.map(({ item, props, key, ...rest }: any) => (
            <animated.div
              {...rest}
              key={key}
              style={{
                overflowY: 'auto',
                ...props,
              }}
            >
              <Switch location={item}>
                <Route path={routes.LANDING} component={Landing} exact />
                <Route path={routes.ACCOUNT_LOGIN_START} component={EmailLoginStart} />
                <Route path={routes.ACCOUNT_LOGIN_DONE} component={EmailLoginEnd} />
                <Route path={routes.LOGGED_IN_HOME} component={Teams} />
                <Route path={routes.ONBOARD} component={Onboard} />
                <Route path={'/test'} component={StripeScreen} />
                <Route component={PageNotFound} />
              </Switch>
            </animated.div>
          ))}
        </OptimizelyProvider>
      </userContext.Provider>
    </React.Fragment>
  )
}

export default App
