import React, { useCallback, useState, useEffect } from 'react'
import {
  FullScreen,
  BackArrowContainer,
  LeftColumn,
  BlueContainer,
  CompanyLogoContainer,
  CompanyLogo,
  LoaderSpinnerContainer,
  Subtitle,
  TextContainer,
  NameText,
  RightColumn,
  TitleText,
  SubtitleText,
  ActionButton,
} from './EmailLoginStart'
import { ArrowLeft } from 'react-feather'
import { DynamicLogoImage } from '../components/DynamicLogoImage'
// import { LoaderSpinner } from '../components/LoadingSpinner'
import { useAuthSession } from '../hooks/useAuthSession'
import { routes } from '../util/routes'
import address from 'email-addresses'
import { ReactComponent as DragAndDropIcon } from '../images/drag-and-drop.svg'
import { RouteComponentProps } from 'react-router-dom'
import {
  ApiRegistrationResponse,
  RegistrationError,
  REGISTRATION_ERROR_CODES,
  StatusErrorObject,
  StatusSuccessObject,
  REGISTRATION_STATUS_SUCCESS_CODES,
} from 'corpoclash-shared'
import { registerEmail, getAccountStatus, updateUserProfile, updateCompanyProfile } from '../util/api'
import { stringify } from 'querystring'
import { useAPIPolling } from '../hooks/useApiPoll'
import { LoadingSpinner } from '../components/LoadingSpinner'

export interface OnboardProps {}

const Onboard = (props: OnboardProps & RouteComponentProps) => {
  const [companyNameInput, setCompanyNameInput] = useState()
  const [employeeFirstNameInput, setEmployeeFirstNameInput] = useState()

  const [valid, setValid] = useState(true)
  const { firebaseUser } = useAuthSession()

  const checkIfEmailIsVerified = useCallback(async (): Promise<REGISTRATION_STATUS_SUCCESS_CODES | undefined> => {
    if (!valid) {
      return undefined
    }

    if (!firebaseUser || !firebaseUser.email) {
      return undefined
    }
    const jwt = await firebaseUser.getIdToken()
    try {
      const accountStatusRes = await getAccountStatus(firebaseUser.email, jwt)
      const data = accountStatusRes.data
      if ((data as StatusErrorObject).errorCode) {
        const error = data as StatusErrorObject
        console.error('error in getEmailStatus', error)
        return undefined
      }
      const { status } = data as StatusSuccessObject
      return status
    } catch (e) {
      console.error(e)
      return undefined
    }
  }, [firebaseUser, valid])

  // Validation, if we don't have a valid firebase user, reset everything
  React.useEffect(() => {
    if (!firebaseUser) {
      setValid(false)
      props.history.push(`${routes.LANDING}`)
    }
  }, [firebaseUser, props.history])

  const accountStatus = useAPIPolling({
    onError: err => console.log(err),
    fetchFunc: checkIfEmailIsVerified,
    initialState: undefined,
    delay: 1500,
  })

  // On complete, route to dashboard
  React.useEffect(() => {
    if (!accountStatus) {
      return
    }
    if (accountStatus === REGISTRATION_STATUS_SUCCESS_CODES.COMPLETE) {
      return props.history.replace(routes.LOGGED_IN_HOME)
    }
  }, [accountStatus, props.history])

  const handleCompanyFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!firebaseUser) {
        return
      }
      const email = firebaseUser.email
      if (!email) {
        return
      }
      const jwt = await firebaseUser.getIdToken()
      await updateCompanyProfile(jwt, { domain: '0xproject.com', name: '0x' })
    },
    [firebaseUser]
  )

  const handleEmployeeFormSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (!firebaseUser) {
        return
      }
      const email = firebaseUser.email
      if (!email) {
        return
      }
      const jwt = await firebaseUser.getIdToken()
      await updateUserProfile(email, jwt, { firstName: 'John', lastName: 'Johnson' })
    },
    [firebaseUser]
  )

  useEffect(() => {
    if (!firebaseUser || !firebaseUser.email) {
      return
    }
    if (accountStatus !== REGISTRATION_STATUS_SUCCESS_CODES.USER_NOT_FOUND) {
      return
    }

    const attemptAuthedRegister = async () => {
      if (!firebaseUser || !firebaseUser.email) {
        return
      }
      console.log('resigeteding!')
      const jwt = await firebaseUser.getIdToken()
      const email = firebaseUser.email
      let registerResponse: ApiRegistrationResponse
      try {
        registerResponse = await registerEmail(firebaseUser.email, jwt)
      } catch (e) {
        console.log('in error catcher, network error or something not logic based')
        console.log(e)
        return
      }
      if ((registerResponse as RegistrationError).errorCode) {
        let error = registerResponse as RegistrationError
        if (error.errorCode === REGISTRATION_ERROR_CODES.EMAIL_EXISTS) {
          console.log('user already exists')
          return
        }
        console.log('error/warning', registerResponse)
        return
      }
    }
    attemptAuthedRegister()
  }, [firebaseUser, accountStatus, props.history])

  if (!firebaseUser || !firebaseUser.email) {
    return null
  }

  // const { email } = firebaseUser

  // let domain = ''
  // if (email) {
  //   const res = address.parseOneAddress(email) as address.ParsedMailbox
  //   domain = res.domain
  // }

  // if (accountStatus === REGISTRATION_STATUS_SUCCESS_CODES.USER_NOT_FOUND) {
  //   // Spinner...
  //   return null;
  // }

  if (accountStatus === REGISTRATION_STATUS_SUCCESS_CODES.COMPANY_NEEDS_MORE_INFO) {
    return (
      <div>
        <form onSubmit={handleCompanyFormSubmit}>
          <input value={companyNameInput} onChange={e => setCompanyNameInput(e.target.value)} />
          <button type={'submit'}>Submit company info</button>
        </form>
      </div>
    )
  }

  if (accountStatus === REGISTRATION_STATUS_SUCCESS_CODES.EMPLOYEE_NEEDS_MORE_INFO) {
    return (
      <div>
        <form onSubmit={handleEmployeeFormSubmit}>
          <input value={employeeFirstNameInput} onChange={e => setEmployeeFirstNameInput(e.target.value)} />
          <button type={'submit'}>Submit employee info</button>
        </form>
      </div>
    )
  }

  return (
    <FullScreen>
      <div>
        <LoadingSpinner />
      </div>
      {/* <BackArrowContainer onClick={() => props.history.goBack()}>
        <ArrowLeft color={'#ffffff'} />
      </BackArrowContainer>
      <LeftColumn>
        <BlueContainer style={{ marginBottom: 20 }}>
          <CompanyLogoContainer>
            <CompanyLogo>
              <DynamicLogoImage size={88} domain={domain} />
            </CompanyLogo>
          </CompanyLogoContainer>
          <LoaderSpinnerContainer>
            <LoaderSpinner margin={11} size={16} />
          </LoaderSpinnerContainer>
          <DragAndDropIcon height={92} width={92} />
          <Subtitle>subtitle here...</Subtitle>
        </BlueContainer>
        <TextContainer>
          <NameText>{email}</NameText>
        </TextContainer>
      </LeftColumn>

      <RightColumn>
        <TitleText>onboaridng</TitleText>
        <SubtitleText>onboarding</SubtitleText>
        <ActionButton>Resend</ActionButton>
      </RightColumn> */}
    </FullScreen>
  )
}

export { Onboard }
