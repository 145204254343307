import * as FullStory from '@fullstorydev/browser'

export enum ANALYTICS_EVENTS {
  'FILLED_OUT_SIGNUP_FORM' = 'FILLED_OUT_SIGNUP_FORM',
}

const analytics = {
  logEvent: (
    eventName: string,
    eventProperties: {
      [key: string]: any
    }
  ) => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    FullStory.event(eventName, eventProperties)
  },
  identify: (uid: string, customVars?: FullStory.UserVars | undefined) => {
    if (process.env.NODE_ENV !== 'production') {
      return
    }
    FullStory.identify(uid, customVars)
  },
  logGeneral: (logLevel: FullStory.LogLevel, msg: string) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(msg)
      return
    }
    FullStory.log(logLevel, msg)
  },
}

export { analytics }
