import React, { useState } from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'
import Div100vh from 'react-div-100vh'
import firebase from 'firebase/app'
import qs from 'query-string'
import { format } from 'date-fns-tz'
import { useMedia } from 'react-use'
import { RouteComponentProps } from 'react-router-dom'
import { useFormik } from 'formik'
import { useTrail, animated, config, useTransition } from 'react-spring'
import picture from '../images/hero-background.png'
import bgText from '../images/hero-text-bg.png'
import leagueBrand from '../images/league.png'
import csgoBrand from '../images/csgo.png'
import fortniteBrand from '../images/fortnite.png'
import { ScoreSummary } from '../components/ScoreSummary'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { useAuthSession } from '../hooks/useAuthSession'
import { defaultTheme } from '../theme'
import { EMAIL_LOGIN_LOCAL_STORAGE_KEY } from './EmailLoginEnd'
import { routes } from '../util/routes'
import { analytics, ANALYTICS_EVENTS } from '../util/analytics'
import { getFeatureFlags } from '../util/configs'
import { requestInvite } from '../util/api'

const isLocal = (): boolean => {
  return window.location.host.includes('localhost:3000')
}

const PageLayout = styled(Div100vh)`
  background: #010108;
  color: #ffffff;
  position: absolute;
  width: 100vw;
  overflow-y: auto;
`

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 24px;
  padding-top: 60px;
  ${defaultTheme.mediaQueries.lg} {
    padding-top: 140px;
  }
`

const TitleTop = styled.div`
  font-weight: 600;
  font-size: 40px;
  color: #ffffff;
  padding-left: 4px;
  z-index: 2;
`

const Subtitle = styled.div`
  font-size: 24px;
  color: #aeb5c4;
  margin-bottom: 8px;
  line-height: 30px;
  max-width: 400px;
`

const FirstContainer = styled(animated.div)`
  width: 100%;
  max-width: 1024px;
  margin-bottom: 60px;
`

const HeroBackgroundImageContainer = styled(animated.div)`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: fill;
  overflow: hidden;
  max-height: 80%;
  background: #010108;
`

const Img = styled.img`
  width: 100%;
`

const BottomImageFade = styled.div`
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #010108 100%);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
`

const HeroInformationContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: flex-end;
  flex-wrap: wrap;
`

const ClashHeroText = styled.div`
  color: white;
  background: url(${bgText}) no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  padding-right: 30px;
  background-position-x: center;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 120px;
  letter-spacing: -0.04em;
`

const SecondContainer = styled(animated.div)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
`

const EmailInputLabel = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 12px;
`

const EmailInputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

const EmailInputForm = styled.form`
  width: 100%;
  display: flex;
  height: 60px;
`

const EmailInput = styled.input`
  background: #121c31;
  font-size: 18px !important;
  line-height: 22px;
  color: #ffffff;
  border: none;
  max-width: 512px;
  flex-basis: 512px;
  /* width: 100%; */
  padding: 10px 15px;
  ${defaultTheme.mediaQueries.sm} {
    padding: 19px 30px;
  }
  font-family: 'Roobert', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  ::placeholder {
    color: #7c8cae;
  }
`

const EmailSubmitContainer = styled.div``

const EmailSubmitButton = styled.button`
  max-width: 160px;
  width: 75px;
  height: 100%;
  margin-left: 9px;
  padding: 4px;
  font-family: 'Roobert', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%), #56f2c3;
  box-shadow: inset 0px -4px 18px #ffffff;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #010108;
  ${defaultTheme.mediaQueries.sm} {
    width: 160px;
    font-size: 18px;
  }
`

export interface ThirdContainerProps {
  trail: any
}

const ThirdContainer = styled(animated.div)`
  position: relative;
  z-index: 1;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 1024px;
  width: 100%;
` as any

const SectionLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  align-items: center;
  margin-bottom: 30px;
`

const SectionLabelColor = styled.div`
  width: 10px;
  height: 30px;
  background: ${props => props.color || '#FD2D49'};
  border-radius: 2px;
  margin-right: 15px;
`

const SectionLabelText = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const SectionContentContainer = styled.div`
  display: grid;
  grid-gap: 33px;
  margin-bottom: 80px;
  grid-template-columns: 1fr;
  ${defaultTheme.mediaQueries.lg} {
    grid-template-columns: 1fr 1fr;
  }
`

const RecentMatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin-bottom: 80px;
`

const RecentMatchRowContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #121c31;
  padding: 20px 0;
`

const RecentMatchTitleImg = styled.img`
  max-height: 32px;
  width: 88px;
`

const RecentMatchTimestampText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: #7c8cae;
`

const FirstTwoSectionsContainer = styled(animated.div)`
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: 1024px;
  margin-bottom: 60px;
  z-index: 1;
  ${defaultTheme.mediaQueries.lg} {
    flex-direction: row;
    margin-bottom: 120px;
  }
`

const MainPanelContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
`

const LogoPanelContainer = styled(animated.div)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${defaultTheme.mediaQueries.lg} {
    flex-direction: column;
    justify-content: flex-end;
  }
`

const GradientBoxOne = styled.div`
  position: absolute;
  left: -40px;
  top: 0;
  bottom: 0;
  background: linear-gradient(270deg, #121c31 0%, rgba(18, 28, 49, 0.5) 100%);
  width: 30px;
  z-index: 2;
`

const GradientBoxTwo = styled(GradientBoxOne)`
  left: -80px;
  background: linear-gradient(90deg, rgba(18, 28, 49, 0) 0%, rgba(18, 28, 49, 0.5) 100%);
`

const LogoContainer = styled.div`
  margin-top: 60px;
`

const LogoImg = styled.img``

const MatchCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #121c31;
  padding-bottom: 20px;
`

const StyledReactPlayer = styled(ReactPlayer)`
  margin-bottom: 23px;
`

const ScoreSummaryContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`

const EmailInputErrorContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  color: #ff6c80;
`

const EmailInputSuccessContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  color: ${defaultTheme.colorIntents.success};
`

const AnimatedEmailInputErrorContainer = animated(EmailInputErrorContainer)
const AnimatedEmailSuccessContainer = animated(EmailInputSuccessContainer)

const Landing = (props: RouteComponentProps) => {
  const isWide = useMedia(`(min-width: ${defaultTheme.breakpoints.md})`)

  React.useEffect(() => {
    // Don't hold on to sessions while dev
    if (process.env.NODE_ENV === 'development') {
      firebase.auth().signOut()
    }
  }, [])

  const [formSubmitSuccessMessage, setFormSubmitSuccessMessage] = useState<string | undefined>(undefined)

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validate: async ({ email }) => {
      setFormSubmitSuccessMessage(undefined)
      const errors: Partial<{
        email: string
      }> = {}

      if (!email) {
        errors.email = 'Work email required'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        errors.email = 'Invalid email address format'
      }

      // TODO(johnrjj) - I'd like to validate email within here (call to api to verify biz email)
      // if (email.endsWith('gmail.com') || email.endsWith('aol.com') || email.endsWith('yahoo.com')) {
      //   errors.email = 'Please enter a valid work email'
      // }

      return errors
    },
    onSubmit: async ({ email }, actions) => {
      // reset states
      actions.setStatus(undefined)
      setFormSubmitSuccessMessage(undefined)

      const trimmedEmail = email.trim()

      const inviteOnlyMode = await getFeatureFlags().then(ff => ff.INVITE_ONLY_MODE)
      try {
        if (!inviteOnlyMode) {
          // Talk to firebase...
          // Testing, only send to me!
          if (trimmedEmail === 'johnrjj@gmail.com' || trimmedEmail === 'john@0xproject.com') {
            await firebase.auth().sendSignInLinkToEmail(trimmedEmail, {
              url: isLocal() ? `http://localhost:3000/auth/link` : `https://corpoclash.com/auth/link`,
              handleCodeInApp: true,
            })
          }
          try {
            window.localStorage.setItem(EMAIL_LOGIN_LOCAL_STORAGE_KEY, trimmedEmail)
          } catch (e) {
            // Silently fail for now
            console.error('Could not set local storage')
          }
        } else {
          // this path ...
          const { requested, alreadyExists, blockedDomain } = await requestInvite(email)
          if (requested) {
            setFormSubmitSuccessMessage(`Invite requested. You're on the list!`)
          } else {
            if (alreadyExists) {
              actions.setFieldError('email', `You've already signed up!`)
            } else if (blockedDomain) {
              actions.setFieldError('email', `Only corporate emails are allowed to sign up.`)
            } else {
              actions.setFieldError('email', `Error signing up`)
            }
          }
        }
        if (!inviteOnlyMode) {
          props.history.push(
            `${routes.ACCOUNT_LOGIN_START}?${qs.stringify({
              email: trimmedEmail,
            })}`
          )
        }
        actions.setSubmitting(false)
        analytics.logEvent(ANALYTICS_EVENTS.FILLED_OUT_SIGNUP_FORM, {
          email: trimmedEmail,
        })
      } catch (e) {
        setFormSubmitSuccessMessage(undefined)
        console.log(e)
        actions.setErrors({
          email: 'Error signing up, try again later',
        })
        actions.setSubmitting(false)
      }
    },
  })

  const inputErrorAnimations = useTransition(formik.errors.email, email => email, {
    from: { position: 'absolute', opacity: 0, y: 5 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 5 },
    unique: true,
  })

  const inputSuccessAnimations = useTransition(formSubmitSuccessMessage, msg => msg, {
    from: { position: 'absolute', opacity: 0, y: 5 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 5 },
    unique: true,
  })

  const trail = useTrail(7, {
    config: config.slow,
    opacity: 1,
    y: 0,
    delay: 50,
    from: { opacity: 0, y: -5 },
  })

  return (
    <PageLayout>
      <HeroBackgroundImageContainer
        key={0}
        style={{
          opacity: trail[0].opacity,
          transform: trail[0].y.to(y => `translate3d(0,${y}px,0)`),
        }}
      >
        <Img src={picture} />
        <BottomImageFade />
      </HeroBackgroundImageContainer>
      <PageWrapper>
        <FirstTwoSectionsContainer
          key={1}
          style={{
            ...trail[1],
            transform: trail[1].y.to(y => `translate3d(0,${y}px,0)`),
          }}
        >
          <MainPanelContainer>
            <FirstContainer
              key={2}
              style={{
                ...trail[2],
                transform: trail[2].y.to(y => `translate3d(0,${y}px,0)`),
              }}
            >
              <TitleTop>Corpo</TitleTop>
              <HeroInformationContainer>
                <ClashHeroText>Clash</ClashHeroText>
                <Subtitle>Create or join your company’s team and compete with other startups.</Subtitle>
              </HeroInformationContainer>
            </FirstContainer>
            <SecondContainer
              key={3}
              style={{
                ...trail[3],
                transform: trail[3].y.to(y => `translate3d(0,${y}px,0)`),
              }}
            >
              <EmailInputLabel>Your corporate e-mail</EmailInputLabel>
              <EmailInputContainer>
                <EmailInputForm onSubmit={formik.handleSubmit}>
                  <EmailInput
                    type="email"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder={'yourname@company.com'}
                  ></EmailInput>
                  <EmailSubmitContainer>
                    <EmailSubmitButton disabled={formik.isSubmitting} type={'submit'}>
                      {formik.isSubmitting ? <LoadingSpinner size={8} color={'#010108'} /> : <div>Request Invite</div>}
                    </EmailSubmitButton>
                  </EmailSubmitContainer>
                </EmailInputForm>
                <GradientBoxOne></GradientBoxOne>
                <GradientBoxTwo></GradientBoxTwo>
              </EmailInputContainer>
              {inputErrorAnimations.map(
                ({ item, key, props }) =>
                  item && (
                    <AnimatedEmailInputErrorContainer key={key} style={props as any}>
                      {item}
                    </AnimatedEmailInputErrorContainer>
                  )
              )}
              {inputSuccessAnimations.map(
                ({ item, key, props }) =>
                  item && (
                    <AnimatedEmailSuccessContainer key={key} style={props as any}>
                      {item}
                    </AnimatedEmailSuccessContainer>
                  )
              )}
            </SecondContainer>
          </MainPanelContainer>
          <LogoPanelContainer
            key={4}
            style={{
              ...trail[4],
              transform: trail[4].y.to(y => `translate3d(0,${y * 0.5}px,0)`),
            }}
          >
            <LogoContainer>
              <LogoImg src={leagueBrand} />
            </LogoContainer>
            <LogoContainer>
              <LogoImg src={fortniteBrand} />
            </LogoContainer>
            <LogoContainer>
              <LogoImg src={csgoBrand} />
            </LogoContainer>
          </LogoPanelContainer>
        </FirstTwoSectionsContainer>
        {isWide && <BelowTheFoldContainer trail={trail} />}
      </PageWrapper>
    </PageLayout>
  )
}

export { Landing }

const BelowTheFoldContainer = ({ trail }: any) => {
  return (
    <ThirdContainer
      key={5}
      style={{
        ...trail[5],
        transform: trail[5].y.to((y: number) => `translate3d(0,${y}px,0)`),
      }}
    >
      {/* <SectionLabelContainer>
        <SectionLabelColor></SectionLabelColor>
        <SectionLabelText>Live Matches</SectionLabelText>
      </SectionLabelContainer>
      <SectionContentContainer>
        <MatchCardContainer>
          <StyledReactPlayer
            onReady={() => console.log('now show')}
            style={{ borderRadius: 8 }}
            width={'100%'}
            url="https://www.twitch.tv/tfue"
          />
          <ScoreSummary
            companyA={{
              domain: 'facebook.com',
              companyName: 'Facebook',
              logoUrl: '#',
            }}
            companyB={{
              domain: 'twitch.tv',
              companyName: 'Twitch',
              logoUrl: '#',
            }}
            matchDetails={{
              score: {
                companyA: '12',
                companyB: '13',
              },
            }}
          ></ScoreSummary>
        </MatchCardContainer>

        <MatchCardContainer>
          <StyledReactPlayer
            onReady={() => console.log('now show')}
            style={{ borderRadius: 8 }}
            width={'100%'}
            url="https://www.twitch.tv/tfue"
          />
          <ScoreSummary
            companyA={{
              domain: 'facebook.com',
              companyName: 'Facebook',
              logoUrl: '#',
            }}
            companyB={{
              domain: 'twitch.tv',
              companyName: 'Twitch',
              logoUrl: '#',
            }}
            matchDetails={{
              score: {
                companyA: '12',
                companyB: '13',
              },
              stake: '100',
            }}
          ></ScoreSummary>
        </MatchCardContainer>
      </SectionContentContainer> */}

      <SectionLabelContainer>
        <SectionLabelColor color={'#56F2C3'}></SectionLabelColor>
        <SectionLabelText>Past Matches</SectionLabelText>
      </SectionLabelContainer>
      <RecentMatchesContainer>
        <RecentMatchRowContainer>
          <RecentMatchTitleImg src={leagueBrand} />
          <ScoreSummaryContainer>
            <ScoreSummary
              companyA={{
                domain: 'facebook.com',
                companyName: 'Facebook',
                logoUrl: '#',
              }}
              companyB={{
                domain: 'twitch.tv',
                companyName: 'Twitch',
                logoUrl: '#',
              }}
              matchDetails={{
                score: {
                  companyA: '12',
                  companyB: '13',
                },
                stake: '100',
              }}
            ></ScoreSummary>
          </ScoreSummaryContainer>
          <RecentMatchTimestampText>
            {format(new Date(2019, 11, 18), 'P p z', {
              timeZone: 'America/New_York',
            })}
          </RecentMatchTimestampText>
        </RecentMatchRowContainer>

        <RecentMatchRowContainer>
          <RecentMatchTitleImg src={csgoBrand} />
          <ScoreSummaryContainer>
            <ScoreSummary
              companyA={{
                domain: 'ultimatesoftware.com',
                teamName: 'UltiAces',
                companyName: 'Ultimate Software',
                logoUrl: '#',
              }}
              companyB={{
                domain: 'slack.com',
                teamName: 'HashTag',
                companyName: 'Slack',
                logoUrl: '#',
              }}
              matchDetails={{
                score: {
                  companyA: '17',
                  companyB: '13',
                },
                stake: '150',
              }}
            ></ScoreSummary>
          </ScoreSummaryContainer>
          <RecentMatchTimestampText>
            {format(new Date(2019, 11, 12), 'P p z', {
              timeZone: 'America/New_York',
            })}
          </RecentMatchTimestampText>
        </RecentMatchRowContainer>

        <RecentMatchRowContainer>
          <RecentMatchTitleImg src={fortniteBrand} />
          <ScoreSummary
            companyA={{
              domain: 'netflix.com',
              companyName: 'Netflix',
              logoUrl: '#',
            }}
            companyB={{
              domain: 'amazon.com',
              companyName: 'Amazon Prime',
              logoUrl: '#',
            }}
            matchDetails={{
              score: {
                companyA: '12',
                companyB: '13',
              },
              stake: '100',
            }}
          ></ScoreSummary>
          <RecentMatchTimestampText>
            {format(new Date(2019, 11, 4), 'P p z', {
              timeZone: 'America/New_York',
            })}
          </RecentMatchTimestampText>
        </RecentMatchRowContainer>
      </RecentMatchesContainer>
    </ThirdContainer>
  )
}
