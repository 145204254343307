import axios, { AxiosError } from 'axios'
import qs from 'query-string'
import {
  ApiRegistrationResponse,
  ApiRegistrationStatusResponse,
  RegistrationError,
  UpdateProfilePayload,
  UpdateProfileApiResponse,
  UpdateCompanyApiResponse,
  UpdateCompanyPayload,
  CheckEmailResponse,
  RequestInviteSuccessResponse,
} from 'corpoclash-shared'

const API_ROOT_URL = process.env.REACT_APP_BACKEND_ENDPOINT_ROOT || 'http://localhost:5000'

export type RegisterEmailSuccessResponse = number

const registerEmail = async (email: string, jwt: string) => {
  try {
    const res = await axios.post<ApiRegistrationResponse>(
      `${API_ROOT_URL}/auth/register`,
      { email },
      {
        headers: { Authorization: `Bearer ${jwt}` },
      }
    )
    return res.data
  } catch (e) {
    if ((e as AxiosError).response) {
      return e.response.data as RegistrationError
    }
    throw e
  }
}

const getSignedImageUrl = async (jwt: string) => {
  return axios.post<{ url: string }>(
    `${API_ROOT_URL}/user/profile/image/upload/link`,
    {},
    {
      headers: { Authorization: `Bearer ${jwt}` },
    }
  )
}

const checkIfEmailVerified = async (email: string) => {
  return axios.get<{ verified: boolean }>(`${API_ROOT_URL}/auth/email/status?${qs.stringify({ email })}`)
}

const getAccountStatus = async (email: string, jwt: string) => {
  return axios.get<ApiRegistrationStatusResponse>(`${API_ROOT_URL}/auth/register/status?${qs.stringify({ email })}`, {
    headers: { Authorization: `Bearer ${jwt}` },
  })
}

// User can self update (limited update access)
const updateUserProfile = async (email: string, jwt: string, data: Partial<UpdateProfilePayload>) => {
  return axios.post<UpdateProfileApiResponse>(
    `${API_ROOT_URL}/user/profile/update`,
    {
      email,
      ...data,
    },
    {
      headers: { Authorization: `Bearer ${jwt}` },
    }
  )
}

// User can self update a company profile (limited update ability)
const updateCompanyProfile = async (jwt: string, data: UpdateCompanyPayload) => {
  return axios.post<UpdateCompanyApiResponse>(
    `${API_ROOT_URL}/company/update`,
    {
      ...data,
    },
    {
      headers: { Authorization: `Bearer ${jwt}` },
    }
  )
}

/**
 * Is corporate email
 */
const isCorporateEmail = async (email: string): Promise<boolean> => {
  const res = await axios.post<CheckEmailResponse>(`${API_ROOT_URL}/email/check-email?${qs.stringify({ email })}`)
  const { valid } = res.data
  return valid
}

const requestInvite = async (email: string): Promise<RequestInviteSuccessResponse> => {
  const res = await axios.get<RequestInviteSuccessResponse>(
    `${API_ROOT_URL}/auth/request-invite?${qs.stringify({ email })}`
  )
  const data = res.data
  return data
}

const backendApiClient = {
  registerEmail,
  getSignedImageUrl,
  getAccountStatus,
  checkIfEmailVerified,
  updateUserProfile,
  updateCompanyProfile,
  isCorporateEmail,
  requestInvite,
}

export {
  registerEmail,
  updateUserProfile,
  updateCompanyProfile,
  getSignedImageUrl,
  getAccountStatus,
  checkIfEmailVerified,
  backendApiClient,
  isCorporateEmail,
  requestInvite,
}
