import React from 'react'
import styled from 'styled-components'
import { defaultTheme } from '../theme'
import { DynamicLogoImage } from './DynamicLogoImage'
import { COMMON_COMPANY_API_ERROR_CODES } from 'corpoclash-shared'

const ScoreSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const CompanyNameText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  /* padding: 5px 0; */
  color: #ffffff;
`

const CompanyNameTextLeft = styled(CompanyNameText)`
  text-align: right;
`

const CompanyNameTextRight = styled(CompanyNameText)``

const CompanyNameAndTeamNameContainerLeft = styled(CompanyNameText)`
  margin-right: 10px;
`

const CompanyNameAndTeamNameContainerRight = styled(CompanyNameText)`
  margin-left: 10px;
`

// Team A detail container
const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`

// Team B detail container
const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: flex-start;
`
// Match / stake details
const MiddleContainer = styled.div`
  height: 100%;
  /* flex-basis: 200px; */
  max-height: 36px;
  display: flex;
  background: rgba(18, 28, 49, 0.4);
  border: 1px solid #121c31;
  border-radius: 70px;
  /* flex: 1; */
  padding: 10px 15px;
  margin: 0px 15px;
  align-items: center;
  justify-content: space-between;
`

const ScoreText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
`

const StakeText = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #7c8cae;
  padding: 0 30px;
`

const CompanyNameAndTeamNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamNameText = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 160%;
  color: #7c8cae;
  margin-top: 2px;
`

const TeamNameTextLeft = styled(TeamNameText)`
  text-align: right;
`

const TeamNameTextRight = styled(TeamNameText)`
  text-align: left;
`

export interface ScoreSummaryProps {
  companyA: {
    teamName?: string
    companyName: string
    domain: string
    logoUrl?: string
  }
  companyB: {
    teamName?: string
    companyName: string
    domain: string
    logoUrl?: string
  }
  matchDetails: {
    stake?: string
    score: {
      companyA: string
      companyB: string
    }
  }
}

const ScoreSummary: React.FC<ScoreSummaryProps> = ({ companyA, companyB, matchDetails }) => {
  return (
    <ScoreSummaryContainer>
      <LeftContainer>
        <CompanyNameAndTeamNameContainerLeft>
          <CompanyNameTextLeft>{companyA.teamName ? companyA.teamName : companyA.companyName}</CompanyNameTextLeft>
          {companyA.teamName && <TeamNameTextLeft>{companyA.companyName}</TeamNameTextLeft>}
        </CompanyNameAndTeamNameContainerLeft>

        <DynamicLogoImage size={32} domain={companyA.domain}></DynamicLogoImage>
      </LeftContainer>
      <MiddleContainer>
        <ScoreText>{matchDetails.score.companyA}</ScoreText>
        <StakeText>${matchDetails.stake || '0'} at stake</StakeText>
        <ScoreText>{matchDetails.score.companyB}</ScoreText>
      </MiddleContainer>
      <RightContainer>
        <DynamicLogoImage size={32} domain={companyB.domain}></DynamicLogoImage>

        <CompanyNameAndTeamNameContainerRight>
          <CompanyNameTextRight>{companyB.teamName ? companyB.teamName : companyB.companyName}</CompanyNameTextRight>
          {companyB.teamName && <TeamNameTextRight>{companyB.companyName}</TeamNameTextRight>}
        </CompanyNameAndTeamNameContainerRight>
      </RightContainer>
    </ScoreSummaryContainer>
  )
}

export { ScoreSummary }
