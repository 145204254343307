import React from 'react'

// Need a way to reset the wait ....

// Used to avoid flash of content
const useHasWaited = (timeInMs: number = 350): boolean => {
  const [ready, setReady] = React.useState<boolean>(false)

  React.useEffect(() => {
    const token = setTimeout(() => setReady(true), timeInMs)
    return () => {
      clearTimeout(token)
    }
  }, [timeInMs, setReady])

  return ready
}

export { useHasWaited }
