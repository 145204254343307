import 'firebase/auth'
import React from 'react'
import firebase from 'firebase/app'
import { stringify } from 'query-string'
import { RouteComponentProps } from 'react-router-dom'
import { StatusErrorObject, StatusSuccessObject, REGISTRATION_STATUS_SUCCESS_CODES } from 'corpoclash-shared'
import { useAuthSession } from '../hooks/useAuthSession'
import { getAccountStatus } from '../util/api'
import { routes } from '../util/routes'
import { useHasWaited } from '../hooks/useHasWaited'

// This component logs the user in, then determines where to route them to
// (if they are already registered, direct to home page)
// (if new member, onboard them)

export const EMAIL_LOGIN_LOCAL_STORAGE_KEY = 'emailForSignIn'

const EmailLinkLogin = (props: RouteComponentProps) => {
  const [email, setEmail] = React.useState<string>('')
  const { firebaseUser } = useAuthSession()

  const MAX_USER_WAIT_BEFORE_SPINNER_IN_MS = 700
  const canShowLoadingSpinner = useHasWaited(MAX_USER_WAIT_BEFORE_SPINNER_IN_MS)

  // First try to get email from local storage on mount
  React.useLayoutEffect(() => {
    const maybeEmail = window.localStorage.getItem(EMAIL_LOGIN_LOCAL_STORAGE_KEY)
    if (!maybeEmail) {
      return
    }
    setEmail(maybeEmail)
  }, [])

  // Login with firebase...
  React.useEffect(() => {
    let isLatest = true

    if (!email) {
      return
    }
    const attemptFirebaseLogin = async () => {
      // Confirm the link is a sign-in with email link.
      const maybeSignInLink = window.location.href
      if (firebase.auth().isSignInWithEmailLink(maybeSignInLink)) {
        try {
          const authResult = await firebase.auth().signInWithEmailLink(email, maybeSignInLink)

          // Remove local storage hint
          // window.localStorage.removeItem(EMAIL_LOGIN_LOCAL_STORAGE_KEY)
          console.log(authResult)
        } catch (e) {
          console.error('error logging in with email link', e)
          if (e.code === 'auth/expired-action-code') {
            // Thrown if OTP in email link expires.
            return
          }
          if (e.code === 'auth/invalid-email') {
            // Thrown if the email address is not valid.
            return
          }
          if (e.code === 'auth/user-disabled') {
            // Thrown if the user corresponding to the given email has been disabled.
            return
          }
          throw e
        }
      } else {
        // bad/no link in url...do something here..
      }
    }
    attemptFirebaseLogin()
    return () => {
      isLatest = false
    }
  }, [email])

  // Get account status... (figure out where to direct the user...)
  React.useEffect(() => {
    const getEmailStatus = async () => {
      if (!firebaseUser || !firebaseUser.email) {
        return
      }
      const jwt = await firebaseUser.getIdToken()
      try {
        const accountStatusRes = await getAccountStatus(firebaseUser.email, jwt)
        const data = accountStatusRes.data
        if ((data as StatusErrorObject).errorCode) {
          const error = data as StatusErrorObject
          console.error('error in getEmailStatus', error)
          return
        }
        const success = data as StatusSuccessObject
        if (success.status === REGISTRATION_STATUS_SUCCESS_CODES.COMPLETE) {
          // Ready to go to home page...
          return props.history.replace(
            `${routes.LOGGED_IN_HOME}?${stringify({
              email,
            })}`
          )
        } else {
          // Onboard...
          return props.history.replace(
            `${routes.ONBOARD}?${stringify({
              email,
            })}`
          )
        }
      } catch (e) {
        console.error(e)
      }
    }

    getEmailStatus()
  }, [firebaseUser, email, props.history])

  if (email /* && !firebaseUser */) {
    if (canShowLoadingSpinner) {
      return <div>show dat spinner</div>
    } else {
      return null
    }
  }

  // No email found, user needs to enter email manually..
  return (
    <div>
      {/* TODO, need to handle when logging in from another browser (email won't be in local storage, user needs to input it) */}
      {!email && <input />}
    </div>
  )
}

export { EmailLinkLogin as EmailLoginEnd }
