import React, { createContext, useState, useEffect, useContext } from 'react'
import {
  StripeProvider as StripeProviderInner,
  Elements as ElementsInner,
  injectStripe,
  ReactStripeElements,
} from 'react-stripe-elements'

export const StripeProvider = ({ children }: any) => {
  const [stripe, setStripe] = useState(null)

  const initializeStripe = () => {
    const key = 'pk_test_hXxjTm2s1ePtEdSnh2u0eNJH00ogbyyVS3' //process.env.STRIPE_PUBLISHABLE_KEY as string;
    setStripe((window as any).Stripe(key))
  }

  useEffect(() => {
    if ((window as any).Stripe) {
      initializeStripe()
    } else {
      // Otherwise wait for Stripe script to load
      document.querySelector('#stripe-js')!.addEventListener('load', () => {
        initializeStripe()
      })
    }
  }, [])

  return <StripeProviderInner stripe={stripe}>{children}</StripeProviderInner>
}

const HookContext = createContext([null, null] as [
  ReactStripeElements.StripeProps | null,
  stripe.elements.Elements | null
])
const HookProvider = injectStripe(({ stripe, elements, children }) => {
  return <HookContext.Provider value={[stripe, elements]}>{children}</HookContext.Provider>
})

const Elements: React.FC<{}> = ({ children }) => {
  return (
    <ElementsInner>
      <HookProvider>{children}</HookProvider>
    </ElementsInner>
  )
}

const useStripe = () => useContext(HookContext)

export { Elements, useStripe }
