import { useContext, useEffect } from 'react'
import { userContext } from '../util/user-context'
import { auth } from 'firebase/app'
import 'firebase/auth'
import { analytics } from '../util/analytics'

const useAuthSession = () => {
  const { firebaseUser } = useContext(userContext)

  return { firebaseUser }
}

const signOut = () => auth().signOut()

export { useAuthSession, signOut }
