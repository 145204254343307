import React from 'react'
import { CardElement } from 'react-stripe-elements'
import { Elements, useStripe, StripeProvider } from '../components/Stripe'

const StripeComponentThing = () => {
  const [stripe, elements] = useStripe()
  console.log(stripe, elements)

  const onSubmit = async () => {
    try {
      const res = await stripe?.createToken()
      console.log(res)
    } catch (e) {
      console.error(e)
    }
  }
  return (
    <div style={{ backgroundColor: 'white', height: '100vh' }}>
      <form onSubmit={onSubmit}>
        <CardElement
          className={'example1'}
          iconStyle={'solid'}
          style={{
            base: {
              iconColor: '#c4f0ff',
              color: '#fff',
              fontWeight: 500,
              fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
              fontSize: '16px',
              fontSmoothing: 'antialiased',

              ':-webkit-autofill': {
                color: '#fce883',
              },
              '::placeholder': {
                color: '#87BBFD',
              },
            },
            invalid: {
              iconColor: '#FFC7EE',
              color: '#FFC7EE',
            },
          }}
        />
      </form>
    </div>
  )
}

const StripeScreen = () => {
  return (
    <StripeProvider>
      <Elements>
        <StripeComponentThing />
      </Elements>
    </StripeProvider>
  )
}

export { StripeScreen }
