import React, { useCallback } from 'react'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import { DynamicLogoImage } from '../components/DynamicLogoImage'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import address from 'email-addresses'
import { ArrowLeft } from 'react-feather'
import { RouteChildrenProps } from 'react-router'
import { defaultTheme } from '../theme'
import { useAPIPolling } from '../hooks/useApiPoll'
import { useHasWaited } from '../hooks/useHasWaited'
import { useAuthSession } from '../hooks/useAuthSession'
import { ReactComponent as DragAndDropIcon } from '../images/drag-and-drop.svg'
import { checkIfEmailVerified } from '../util/api'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const BlueContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #121c31;
  border: 1px solid #203258;
  box-sizing: border-box;
  border-radius: 15px;
  padding: 50px 10px 10px 10px;
  color: #556787;
  max-height: 200px;
  height: 200px;
  min-width: 200px;
  ${defaultTheme.mediaQueries.md} {
    padding: 120px 10px 10px 10px;
    max-height: 290px;
    height: 290px;
    width: 200px;
    max-width: 200px;
  }
`

export const LeftColumn = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${defaultTheme.mediaQueries.md} {
    align-items: flex-end;
  }
`

export const RightColumn = styled.div`
  display: flex;
  padding: 0px 28px 50px 28px;
  ${defaultTheme.mediaQueries.md} {
    padding: 90px 60px 130px 60px;
  }
  flex-direction: column;
  max-width: 380px;
`

export const TitleText = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #ffffff;
  margin-bottom: 15px;
`

export const SubtitleText = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #aeb5c4;
  margin-bottom: 15px;
`

export const ActionButton = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #56f2c3;
`

export const NameText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #ffffff;
`

export const FullScreen = styled(Div100vh)`
  background: #010108;
  position: absolute;
  overflow-y: auto;
  left: 0;
  top: 0;
  width: 100vw;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  flex-direction: column-reverse;
  ${defaultTheme.mediaQueries.md} {
    flex-direction: row;
  }
`

export const CompanyLogoContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  flex: 1;
  left: 50%;
  top: 0;
  justify-content: center;
  align-items: center;
`

export const CompanyLogo = styled.div`
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px;
  border-radius: 100%;
  width: 104px;
`

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #556787;
`

export const BackArrowContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 32px;
  cursor: pointer;
`

export const LoaderSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${defaultTheme.mediaQueries.md} {
    max-width: 200px;
  }
`

const EmailLoginStart = (props: RouteChildrenProps) => {
  const { search } = useLocation()
  const params = queryString.parse(search)
  const { email } = params

  const { firebaseUser } = useAuthSession()

  const checkIfEmailIsVerified = useCallback(async (): Promise<boolean> => {
    if (!email) {
      return false
    }
    try {
      const res = await checkIfEmailVerified(Array.isArray(email) ? email[0] : email)
      return res.data.verified
    } catch (e) {
      console.error(e)
      return false
    }
  }, [email])

  const isEmailVerified = useAPIPolling({
    fetchFunc: checkIfEmailIsVerified,
    initialState: undefined,
    delay: 2000,
  })

  const MAX_USER_WAIT_BEFORE_SPINNER_IN_MS = 700
  const canShowLoadingSpinner = useHasWaited(MAX_USER_WAIT_BEFORE_SPINNER_IN_MS)

  // Firebase user syncs across tabs, so if a user logs in in another tab,
  // and the user is waiting on this tab, the firebase user will update
  // and we can react accordingly.
  React.useEffect(() => {
    if (!firebaseUser) {
      return
    }
    // Replace (vs push) or else we can't use the back button (due to looping)
    props.history.replace('/app')
  }, [firebaseUser, props.history])

  let domain = ''
  if (email) {
    const res = address.parseOneAddress(Array.isArray(email) ? email[0] : email) as address.ParsedMailbox
    domain = res.domain
  }

  // Loading status of account
  if (isEmailVerified === undefined) {
    if (canShowLoadingSpinner) {
      console.warn('took too long, user saw a spinnner!!')
      return <div>loading spinner...</div>
    } else {
      return null
    }
  }

  // Status of account loaded, display the relevant info
  return (
    <FullScreen>
      <BackArrowContainer onClick={() => props.history.goBack()}>
        <ArrowLeft color={'#ffffff'} />
      </BackArrowContainer>
      <LeftColumn>
        <BlueContainer style={{ marginBottom: 20 }}>
          <CompanyLogoContainer>
            <CompanyLogo>
              <DynamicLogoImage size={88} domain={domain} />
            </CompanyLogo>
          </CompanyLogoContainer>
          <LoaderSpinnerContainer>
            <LoadingSpinner margin={11} size={16} />
          </LoaderSpinnerContainer>
          {/* <DragAndDropIcon height={92} width={92} /> */}
          <Subtitle>{isEmailVerified ? 'Awaiting email login' : 'Awaiting email confirmation'}</Subtitle>
        </BlueContainer>
        <TextContainer>
          <NameText>{email}</NameText>
        </TextContainer>
      </LeftColumn>

      <RightColumn>
        <TitleText>{isEmailVerified ? 'Check your email for a login link' : 'Please confirm your e-mail'}</TitleText>
        <SubtitleText>
          {isEmailVerified
            ? 'CorpoClash uses email authentication instead of passwords'
            : 'You will be paired based on your company’s current roster.'}
        </SubtitleText>
        <ActionButton>Resend</ActionButton>
      </RightColumn>
    </FullScreen>
  )
}

const AccountUploadProfileAsk = () => {
  return (
    <FullScreen>
      <LeftColumn>
        <BlueContainer style={{ marginBottom: 20 }}>Waiting</BlueContainer>
        <NameText>foofoo</NameText>
      </LeftColumn>

      <RightColumn>
        <TitleText>Upload photo, before you start</TitleText>
        <SubtitleText>You will be paired based on your company’s current roster.</SubtitleText>
        <ActionButton>Upload photo</ActionButton>
        {/* <ActionButton>
          Skip
        </ActionButton> */}
      </RightColumn>
    </FullScreen>
  )
}

export { EmailLoginStart }
