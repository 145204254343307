import * as React from 'react'
import firebase from 'firebase'

interface UserContext {
  firebaseUser?: firebase.User
  loading?: boolean
}

const userContext = React.createContext<UserContext>({
  firebaseUser: undefined,
  loading: undefined,
})

export { userContext }
