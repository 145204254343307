import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import * as FullStory from '@fullstorydev/browser'
import './resets.css'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://20edbc5f4a3b4022a4a0ee8961892e6a@sentry.io/1864456' })
  FullStory.init({ orgId: 'QSZ4Q' })
}

;(ReactDOM as any).createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
