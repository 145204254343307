import React from 'react'
import styled from 'styled-components'
import DefaultImage from '../images/unknown-company.png'

const StyledImage = styled.img<{ size: number }>`
  border-radius: 100%;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`

const ROOT_CLEARBIT_LOGO_URL = 'https://logo.clearbit.com'
const getUrl = (domain: string, size: number = 32) => {
  const src = `${ROOT_CLEARBIT_LOGO_URL}/${domain}?size=${size}`
  return src
}

// https://logo.clearbit.com/segment.com?size=200&greyscale=true

export interface DynamicLogoImageProps {
  domain: string
  size: number
}

const DynamicLogoImage: React.FC<DynamicLogoImageProps> = ({ domain, size }) => {
  const imageRef = React.createRef<HTMLImageElement>()

  const [error, setError] = React.useState<boolean>()

  return (
    <StyledImage
      size={size}
      onError={() => setError(true)}
      ref={imageRef}
      src={error ? DefaultImage : getUrl(domain, size)}
    />
  )
}

export { DynamicLogoImage }
