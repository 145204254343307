import React from 'react'
import styled, { keyframes } from 'styled-components'

const beat = keyframes`
  50% {
    transform: scale(0.375);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

const Beat = styled.div<{
  color: string
  size: number
  margin: number
  i: number
}>`
  display: inline-block;
  background-color: ${props => props.color};
  width: ${props => `${props.size}px`};
  height: ${props => `${props.size}px`};
  margin: ${props => props.margin}px;
  border-radius: 100%;
  animation: ${beat} 2s ${props => (props.i % 2 ? '0s' : '1s')} infinite linear;
  animation-fill-mode: both;
`

export interface LoaderProps {
  size?: number
  color?: string
  margin?: number
}

const LoadingSpinner: React.FC<LoaderProps> = (
  { size, color, margin, ...rest } = { color: '#56F2F2', size: 16, margin: 2 }
) => {
  return (
    <div>
      <Beat color={color || '#56F2F2'} size={size || 16} margin={margin || 2} i={1} {...rest} />
      <Beat color={color || '#56F2F2'} size={size || 16} margin={margin || 2} i={2} {...rest} />
      <Beat color={color || '#56F2F2'} size={size || 16} margin={margin || 2} i={3} {...rest} />
    </div>
  )
}

export { LoadingSpinner }
