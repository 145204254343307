"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // Registration

var REGISTRATION_ERROR_CODES;

(function (REGISTRATION_ERROR_CODES) {
  REGISTRATION_ERROR_CODES["EMAIL_EXISTS"] = "EMAIL_EXISTS";
  REGISTRATION_ERROR_CODES["EMAIL_INVALID"] = "EMAIL_INVALID";
  REGISTRATION_ERROR_CODES["DOMAIN_UNKNOWN"] = "DOMAIN_UNKNOWN";
  REGISTRATION_ERROR_CODES["INVALID_EMAIL_LINK_REGISTER_REQUEST"] = "INVALID_EMAIL_LINK_REGISTER_REQUEST";
})(REGISTRATION_ERROR_CODES = exports.REGISTRATION_ERROR_CODES || (exports.REGISTRATION_ERROR_CODES = {})); // Registration Status


var REGISTRATION_STATUS_SUCCESS_CODES;

(function (REGISTRATION_STATUS_SUCCESS_CODES) {
  REGISTRATION_STATUS_SUCCESS_CODES["USER_NOT_FOUND"] = "USER_NOT_FOUND";
  REGISTRATION_STATUS_SUCCESS_CODES["COMPANY_NOT_FOUND"] = "COMPANY_NOT_FOUND";
  REGISTRATION_STATUS_SUCCESS_CODES["COMPANY_NEEDS_MORE_INFO"] = "COMPANY_NEEDS_MORE_INFO";
  REGISTRATION_STATUS_SUCCESS_CODES["EMPLOYEE_NEEDS_MORE_INFO"] = "EMPLOYEE_NEEDS_MORE_INFO";
  REGISTRATION_STATUS_SUCCESS_CODES["COMPLETE"] = "COMPLETE";
})(REGISTRATION_STATUS_SUCCESS_CODES = exports.REGISTRATION_STATUS_SUCCESS_CODES || (exports.REGISTRATION_STATUS_SUCCESS_CODES = {})); // per router


var COMMON_COMPANY_API_ERROR_CODES;

(function (COMMON_COMPANY_API_ERROR_CODES) {
  COMMON_COMPANY_API_ERROR_CODES[COMMON_COMPANY_API_ERROR_CODES["DOMAIN_MISSING"] = 0] = "DOMAIN_MISSING";
})(COMMON_COMPANY_API_ERROR_CODES = exports.COMMON_COMPANY_API_ERROR_CODES || (exports.COMMON_COMPANY_API_ERROR_CODES = {}));

var COMMON_AUTH_API_ERROR_CODES;

(function (COMMON_AUTH_API_ERROR_CODES) {
  COMMON_AUTH_API_ERROR_CODES[COMMON_AUTH_API_ERROR_CODES["EMAIL_MISSING"] = 0] = "EMAIL_MISSING";
})(COMMON_AUTH_API_ERROR_CODES = exports.COMMON_AUTH_API_ERROR_CODES || (exports.COMMON_AUTH_API_ERROR_CODES = {}));