import React from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { DynamicLogoImage } from '../components/DynamicLogoImage'
import { Route, RouteComponentProps } from 'react-router-dom'
import { ReactComponent as DiscordLogo } from '../images/discord.svg'

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
`

// const BlueContainer = styled.div`

//   display: flex;

//   background: #121C31;
//   border: 1px solid #203258;
//   box-sizing: border-box;
//   border-radius: 15px;
//   padding: 145px 65px;
//   color: #556787;
// `;

// const LeftColumn = styled.div`
//   display: flex;
//   flex-direction: column;
// `;

// const RightColumn = styled.div`
//   display: flex;
//   padding: 90px 60px;
//   flex-direction: column;
//   max-width: 380px;
// `;

// const TitleText = styled.div`
// font-weight: 600;
// font-size: 32px;
// line-height: 38px;
// color: #FFFFFF;
// margin-bottom: 15px;
// `;

// const SubtitleText = styled.div`
// font-size: 18px;
// line-height: 22px;
// color: #AEB5C4;
// margin-bottom: 15px;

// `;

// const ActionButton = styled.div`
// font-size: 18px;
// line-height: 22px;
// color: #56F2C3;
// `;

// const NameText = styled.div`
// font-style: normal;
// font-weight: 600;
// font-size: 24px;
// line-height: 29px;
// text-align: center;
// color: #FFFFFF;
// `;

// const FullScreen = styled.div`
//   height: 100vh;
//   width: 100vw;
//   display: flex;
//   flex-direction: row;
//   flex: 1;
//   justify-content: center;
//   align-items: center;
// `;

const Navbar = styled.div`
  color: #fff;
  display: flex;
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  height: 98px;
  border-bottom: 1px solid #121c31;
  align-items: center;
`

const NavBarRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const NavBarLeftContainer = styled.div``

const NavBarTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
`

const UserName = styled.div`
  font-size: 24px;
  color: #ffffff;
`

const UserAvatarContainer = styled.div`
  padding-right: 15px;
`

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  width: 100%;
  padding-top: 30px;
`

const Banner = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: #0b111f;
  border: 1px solid #263962;
  border-radius: 8px;
`

const BannerText = styled.div`
  font-size: 22px;
  text-align: center;
  color: #ffffff;
  padding: 25px 0;
`

const TeamsHeaderRowContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-bottom: 60px;
`

const TeamsHeaderRowLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TeamTitleAndSubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TeamTitleContainer = styled.div`
  font-weight: bold;
  font-size: 32px;
  color: #ffffff;
  margin-bottom: 10px;
`

const TeamSubtitleContainer = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #aeb5c4;
`

const TeamsHeaderRowRightContainer = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: 10px;
`

const TeamHeaderCallToActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a2849;
  padding: 9px 14px;
  border-radius: 8px;
`

const CallToActionText = styled.div`
  margin-left: 12px;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
`

const TeamIconContainer = styled.div`
  height: 82px;
  width: 82px;
  min-width: 82px;
  border-radius: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%);
  margin-right: 10px;
`

const GameTeamListContainer = styled.div`
  display: flex;
  flex: 1;

  border: 1px solid #131c31;
  background-color: #0c121f;
  padding: 12px 8px;
  border-radius: 8px;
`

const GameTeamListTitleCardContainer = styled.div``

const TeamCardContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-basis: 180px;
  height: 248px;
  position: relative;
  flex-direction: row;

  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`

const TeamCardAvatarContainer = styled.div<{ backgroundColor: string }>`
  height: 100%;
  flex: 1;
  background-color: ${props => props.backgroundColor};
  /* fixes weird border-radius overflow sliver bug */
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
`

const TeamCardFadeOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 33%;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(26, 40, 73, 0) 3.57%, #121b32 61.87%, #0b111f 100%);
`

const TeamCardLabelsOverlayContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const TeamCardLabelOverlayTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

const TeamCardLabelOverlayBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
`

const TeamCardLabelContainer = styled.div`
  background: #0c111f;
  opacity: 0.7;
  box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
`

const TeamCardTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
`

const TeamCardSubtitle = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #ffffff;
`

// This should probably be CSS
const TeamCardSpacer = styled.div`
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
`

const Teams = ({ match }: RouteComponentProps) => {
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      console.warn('nothing found to upload')
      return
    }
    const files = Array.from(e.target.files)

    // const formData = new FormData()

    files.forEach((file, i) => {
      console.log(file)
      // formData.append(i.toString(10), file)
    })

    const signedUrl =
      'https://storage.googleapis.com/img.corpoclash.com/beepboop2.jpg?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=corpo-admin%40corpoclash.iam.gserviceaccount.com%2F20191123%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20191123T202132Z&X-Goog-Expires=3598&X-Goog-SignedHeaders=content-type%3Bhost&X-Goog-Signature=79a3aaf34829620170476218d17d2aee9d8d034614bef75f8ac364225d1c57a9adc7364db72c4bd0cbb899140160ebbfbd179b4d6ccae9ab5b9a1643f43e0749a57fd7d76bf132180f34009da1db8e96a4fbe01a880b35201931e4f272e7d8bb615f1b07458064858fcfb4fc912803b781b8cdca315210e3ab7936c781e961e876fe5ac6335ce6a3b6dc5a2be30bca03fe8c4e433091fe0c9f3420348f742194604562167efbf8b069b77130b84578b7ab075d798d83967c7fd1128dee6b44c797e89fd0033c763d37906b06b76c4f59213dac30c82549cbd11732aa100449386b320ff6459c6a9f08a512dc57c5431cfbf443d3f5f8c404b3edb528f548d9d1'

    axios
      .put(signedUrl, files[0], {
        headers: {
          // REQUIRES EXACT CONTENT-TYPE HEADER SIGNED FROM BACKEND OR ELSE WILL FAIL
          'content-type': 'application/octet-stream',
        },
      })
      .then(x => console.log(x))
      .catch(e => console.error(e))
  }

  const firstName = `Patryk`
  const domain = 'facebook.com'
  const companyName = 'Facebook'
  const availableTeams = 12
  const teammates: Array<Teammate> = [
    {
      avatarImgBgColor: '#E9F9F9',
    },
    {
      avatarImgBgColor: '#C4C4C4',
    },
    {
      avatarImgBgColor: '#968CB6',
    },
    {
      avatarImgBgColor: '#E7EBEC',
    },
  ]
  return (
    <Page>
      <input type="file" id="single" onChange={handleUpload} />

      <Navbar>
        <NavBarLeftContainer>
          <NavBarTitle>Corpo Clash</NavBarTitle>
        </NavBarLeftContainer>
        <NavBarRightContainer>
          <UserAvatarContainer>
            <DynamicLogoImage size={48} domain={domain} />
          </UserAvatarContainer>
          <UserName>{firstName || 'Guest'}</UserName>
        </NavBarRightContainer>
      </Navbar>
      <ContentContainer>
        <Banner style={{ marginBottom: 50 }}>
          <BannerText>Hello {firstName}! Find a team in your favorite game.</BannerText>
        </Banner>
        <TeamsHeaderRowContainer>
          <TeamsHeaderRowLeftContainer>
            <TeamIconContainer>
              <DynamicLogoImage size={66} domain={domain} />
            </TeamIconContainer>

            <TeamTitleAndSubtitleContainer>
              <TeamTitleContainer>Teams in {companyName}</TeamTitleContainer>
              <TeamSubtitleContainer>{availableTeams} actively recruiting</TeamSubtitleContainer>
            </TeamTitleAndSubtitleContainer>
          </TeamsHeaderRowLeftContainer>
          <TeamsHeaderRowRightContainer>
            <TeamHeaderCallToActionContainer>
              <DiscordLogo />
              <CallToActionText>Join our Discord</CallToActionText>
            </TeamHeaderCallToActionContainer>
          </TeamsHeaderRowRightContainer>
        </TeamsHeaderRowContainer>
        <GameTeamListContainer>
          <GameTeamListTitleCardContainer>title stuf here</GameTeamListTitleCardContainer>

          <TeamCard
            bannerMessage={'Join us!'}
            isHot={true}
            winCount={3}
            maxTeamMemberCount={7}
            teamName={'dzn L33t armyy'}
            teammates={teammates}
          />
          <TeamCard
            bannerMessage={'Join us!'}
            isHot={true}
            winCount={3}
            maxTeamMemberCount={7}
            teamName={'dzn L33t armyy'}
            teammates={teammates}
          />
          <TeamCard
            bannerMessage={'Join us!'}
            isHot={true}
            winCount={3}
            maxTeamMemberCount={7}
            teamName={'dzn L33t armyy'}
            teammates={teammates}
          />
        </GameTeamListContainer>
        <Route path={`${match.path}/:topicId`} component={() => <div>meow</div>} />
      </ContentContainer>
    </Page>
  )
}

export interface Teammate {
  avatarImgSrc?: string
  avatarImgBgColor?: string
}

export interface TeamCardProps {
  bannerMessage: string
  maxTeamMemberCount: number
  teamName: string
  isHot: boolean
  winCount: number
  teammates: Array<Teammate>
}

const TeamCard = ({ bannerMessage, maxTeamMemberCount, teamName, teammates, winCount, isHot }: TeamCardProps) => {
  return (
    <TeamCardContainer>
      <TeamCardFadeOverlay></TeamCardFadeOverlay>
      <TeamCardLabelsOverlayContainer>
        <TeamCardLabelOverlayTopContainer>
          <TeamCardLabelContainer>{bannerMessage}</TeamCardLabelContainer>
          <TeamCardLabelContainer>
            {teammates.length} / {maxTeamMemberCount}
          </TeamCardLabelContainer>
        </TeamCardLabelOverlayTopContainer>
        <TeamCardLabelOverlayBottomContainer>
          <TeamCardTitle>{teamName}</TeamCardTitle>
          <TeamCardSubtitle>
            {isHot && '🔥'} {winCount} wins
          </TeamCardSubtitle>
        </TeamCardLabelOverlayBottomContainer>
      </TeamCardLabelsOverlayContainer>
      {teammates.map(teammate => {
        if (teammate.avatarImgSrc) {
          // todo show image
        }
        return (
          <TeamCardAvatarContainer backgroundColor={teammate.avatarImgBgColor || '#C4C4C4'}></TeamCardAvatarContainer>
        )
      })}
    </TeamCardContainer>
  )
}

export { Teams }
